/*
 * Handle everything that concerns the wishlist (add to wishlist, send wishlist, on both product page and wishlist page)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;

function restoreWishlistButtonState() {
    $("#addToWishlistButton span").html(button_add_to_wishlist_initial_label);
    $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event).attr("href", "#");
}

/*
Add to wishlist button on the product page (product_right.php)
*/
function addToWishlist(evt) {
    evt.preventDefault();

    var product_id = $("#hdn_product_id").val();
    var product_refext = $("#hdn_product_refext").val();
    var color_id = $("#couleurProd").val();
    var size_id = $("#tailleProd").val();
    var need_color_id = ($("#couleurProd").length != 0 && $("#couleurProd").val() != 'none');
    var need_size_id = ($("#tailleProd").length != 0 && $("#tailleProd").val() != 'none');

    if (parseInt($('#in_basket').val()) === 1) {
        var id_select = $(this).attr('class');
        product_id = id_select.split('product_towish basketToWishlist id_');

        color_id = "#couleurProd_" + product_id;
        color_id = color_id.replace(',', '');
        color_id = $(color_id).val();

        size_id = "#tailleProd_" + product_id;
        size_id = size_id.replace(',', '');
        size_id = $(size_id).val();

        need_color_id = "#couleurProd_" + product_id;
        need_color_id = need_color_id.replace(',', '');
        need_color_id = $(need_color_id).val();
        need_size_id = "#tailleProd_" + product_id;
        need_size_id = need_size_id.replace(',', '');
        need_size_id = $(need_size_id).val();

        need_color_id = ($(need_color_id).length != 0 && $(need_color_id).val() != 'none');
        need_size_id = ($(need_size_id).length != 0 && $(need_size_id).val() != 'none');
    }

    var is_model = $("#hdn_is_model").val();
    let hasBasket = (parseInt($('#in_basket').val()) === 1);
    let btnWishlist = (hasBasket ? $("#addToWishlistButton_" + product_id) : $("#addToWishlistButton"));

    var datas = {};
    datas.product_id = product_id;
    datas.size_id = size_id;
    datas.color_id = color_id;
    datas.need_color_id = need_color_id;
    datas.need_size_id = need_size_id;
    datas.is_model = is_model;

    // On regarde si le produit est deja dans la wishlist
    if (!btnWishlist.hasClass('existToWishlistButton')) {
        evt.preventDefault();
        if (typeof (button_add_to_wishlist_initial_label) == "undefined") {
            button_add_to_wishlist_initial_label = $("#addToWishlistButton span").html();
        }

        if (!product_id || typeof product_id == "undefined") {
            return false;
        }

        if (hasBasket) {
            datas.product_id = datas.product_id[1];
        }

        from_range = (!!document.getElementById('lightbox_achat_express'));

        $.post(path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), datas, function (resp) {
            $("#addToWishlistButton").parent().removeClass('loading');

            if (resp.error) {
                if (resp.message == "login_required") {
                    var html =
                        '<div class="box_wishlist wishAlert">' +
                        resp.result.error_message +
                        '<div class="btn_container">' +
                        '<div class="button primary" href="#" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</div>" +
                        "</div>" +
                        "</div>";

                    if ($('#lightbox_achat_express').length) {
                        closeMultiShad('lightbox_achat_express');
                    }

                    $("#abox").addClass("wishConnection");
                    alert(html);
                    $("#abox .form_submit").css("display", "none");

                } else if (resp.message != "error") {
                    $(".disabledBtnTooltip").addClass('actif');
                    $('#btn_add_cart').animate({ opacity: 0.2 }, 350)
                        .animate({ opacity: 0.6 }, 350)
                        .animate({ opacity: 0.2 }, 350)
                        .animate({ opacity: 0.6 }, 350);
                } else {
                    alert(Translator.translate(resp.result.error_message));
                    return false;
                }
            } else {
                //evenement DataLayer
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                        'products': {
                            'product_id': product_id,
                            'product_refext': product_refext,
                        }
                    });
                }
                if (hasBasket) {
                    var btn_wish = "#addToWishlistButton_" + product_id;
                    var span_wish = btn_wish + " span";
                    var del_product = "#del_product_" + product_id;

                    btn_wish = btn_wish.replace(',', '');
                    span_wish = span_wish.replace(',', '');
                    del_product = del_product.replace(',', '');

                    $(span_wish).html(resp.result.button_label);
                    $(btn_wish).removeClass("addToWishlistButton").addClass("existToWishlistButton");

                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    $(del_product)[0].click();

                } else {
                    $("#addToWishlistButton span").html(resp.result.button_label);
                    $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");

                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    /* Update wishlist top */
                    var response_basket = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
                    let isMouseOverShowTopWish = false;

                    $("#wishlist_top").html(response_basket);
                    $("#wishlist_top").addClass('temp_hover');
                    topWishlistAppear();

                    function handleWishlist() {
                        if (!$("#show_top_wish").is(":hover")) {
                            setTimeout(function () {
                                $("#wishlist_top").removeClass('temp_hover');
                                topWishlistDisappear();
                            }, 4000);
                        }
                    }

                    let wishlistTimeout;

                    wishlistTimeout = setTimeout(handleWishlist, 5000);

                    $("#show_top_wish").on("mouseenter", function () {
                        clearTimeout(wishlistTimeout);
                        $("#wishlist_top").addClass('temp_hover');
                    });

                    $("#show_top_wish").on("mouseleave", function () {
                        wishlistTimeout = setTimeout(handleWishlist, 5000);
                    });
                }
            }
        });
    } else {
        if (datas.size_id !== "" && btnWishlist.hasClass('existToWishlistButton')) {
            // Ajax remove wishlist
            $.ajax({
                url: path_relative_root + "ajax_remove_from_wishlist.php",
                type: 'post',
                data: datas
            }).done(function (resp) {
                if (!resp.error) {
                    btnWishlist.removeClass("existToWishlistButton");
                    changeBtnAddWishlist(false, product_id);
                }
            });
        } else if (btnWishlist.hasClass('existToWishlistButton')) {
            if (datas.size_id === "") {
                alert(Translator.translate('error_suppr_wishlist_taille'));
            }

            if (datas.color_id === "") {
                alert(Translator.translate('error_suppr_wishlist_couleur'));
            }
        }
    }
}

function addWishListNoConnect(prod_id) {
    $.post(
        path_relative_root + "ajax_add_to_wishlist.php?time=" + Date.now(),

        function (resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox");

            var html =
                '<div class="light_title"></div>';

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $(
                        '<div id="abox">' + html + "</div>"
                    );
                }

                if (resp.message == "login_required") {
                    if ($('#abox .btn_container.btn_cnx').length == 0) {
                        $alertboxBtns
                            .append($(
                                '<div class="btn_container btn_cnx">' +
                                '<button class="button dark"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + prod_id + '\');">' +
                                '<span>' +
                                resp.result.btn_connexion +
                                '</span>' +
                                '</button>' +
                                '</div>')
                            );
                        $alertboxLogin
                            .find(".txt_alert")
                            .html(
                                '<div class="box_wishlist">' +
                                resp.result.error_message +
                                '</div>'
                            );

                    }

                    $('#abox .form_submit').css('display', 'none');
                    $alertBox = $alertboxLogin;
                }

                openMultiShad('abox');
                $alertBox
                    .appendTo("body")
                    .show()
                    .css("z-index", "10100");

            } else {
                $("span", btn).html(resp.result.button_label);
                btn.removeClass("addToWishlistButton").addClass(
                    "existToWishlistButton"
                );
                btn.attr("onclick", "");
                if (typeof isWeddingSpace == "undefined") {
                    btn.attr("href", resp.result.button_link);
                }

                wishlist.push(resp.result.wishlist_row_array);
                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
        }
    );
}

function addWishNoConnect(div_id, user, reference, is_alliance, code_couleur, idprod, wishlist) {
    if (user) {
        ajax_achat_express(reference, is_alliance, code_couleur, idprod, wishlist);
    } else {
        addWishListNoConnect(div_id);
    }
}

function checkProductInWishlist() {
    var productId = $("#produit_id").val();
    var couleurProd = $("#couleurProd");
    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#tailleProd");
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else {
        tailleProd = 0;
    }

    if (typeof productId != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: { 'product_id': productId, 'size_id': tailleProd, 'color_id': couleurProd },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

// Changes the appearance and action of the "add to wishlist" button
function changeBtnAddWishlist(in_wishlist) {
    var btn = $("#addToWishlistButton");
    if (in_wishlist) {
        btn.attr("onclick", "");
        btn.attr("href", path_relative_root + create_link('wishlist'));
        $("#addToWishlistButton span").html(translate('exist_to_wishlist_button_label'));
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", button_add_to_wishlist_initial_click_event);
        btn.attr("href", "javascript:;");
        $("#addToWishlistButton span").html(Translator.translate('add_wishlist'));
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

function wishlistRedirectLogin(div_id) {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
    });
}

function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html('(' + nr + ')');

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $(".wrapper_menu_compte_container .menu_item_links.wishlist").css({ 'display': 'block' });

        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html('(' + nr + ')');
    }
}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = []; // global
    wishlistProductData = [];
    selectedProductsObj = {};
    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");

        if (element.is(':checked')) {
            var price = parseFloat(element.attr('data-price'));

            if (price) {
                totalPrice += price;
            }

            wishlistProductData.push({
                productId: element.attr("data-produit-id"),
                colorId: element.attr("data-color-id"),
                sizeId: element.attr("data-size-id"),
            });
            selectedProductsObj.selectedProducts = JSON.stringify(wishlistProductData);
            selectedProducts.push(element.attr("data-id"));
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + ' :';
            priceSpan.html('0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span>');
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == '')
                text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = '';
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = '' + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + '0';
                }
            }

            priceSpan.html(pricefloor + '<span class="devise">&euro;</span><sup></sup><span class="decimal_price">' + decimal + '</span>');
            txt = '<span class="wishlist_count">' + selectedProducts.length + '</span> ' + text + ' :';
    }

    $(".txt", "#nrArticlesSelected").html(txt);

}

function addProductToCart(wpId, pId, sId, cId) {
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
        {
            product_id: pId,
            size_id: sId,
            color_id: cId
        }, function (resp) {
            if (resp == "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            } else {
                if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                    alert(translate('err_no_points'));
                }
            }
        });
}

/**
 * Show send wishlist lightbox, dynamically if it doesn't exist
 * Used in : wishlist_index.php
 */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

/**
 * Submits wishlist form, with error handling
 * Used in : wishlist_index.php
 */
function sendWishListForm() {

    $("#sendWishListSubmit").css({ display: 'none' });
    $('#wrapper_loader_send').css({ display: 'block' });
    var content_id = $("#send_wishlist_ajax");
    var content;
    if ($('#sendWishListToMails').length && $('#sendWishListToMessage').length && $('#sendWishListYourName').length && $('#sendWishListYourMail').length) {

        var to_mails = $('#sendWishListToMails').val();
        var to_message = $('#sendWishListToMessage').val();
        var your_name = $('#sendWishListYourName').val();
        var your_mail = $('#sendWishListYourMail').val();
        var token_security = $('#token_security_wishlist').val();
        var captcha = $('#g-recaptcha-response').val();

        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form') + '?to_mails=' + to_mails + '&to_message=' + to_message + '&your_name=' + your_name + '&your_mail=' + your_mail + '&token_security=' + token_security + '&g-recaptcha-response=' + captcha
        }).done(function (response) {

            $('.zone_text').css({ border: '' });

            if (response.split(',')[0] == 'ok') {

                var s_adresse = response.substr(3);

                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';

                content = '<div>' + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=' + sing) + '</div>';
                content += '<p style=\"margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;\">' + s_adresse + '</p>';

            }
            if (response == '1' || response == '2') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_mail_friends_invalid') + "</p>";
                $('#sendWishListToMails').addClass('inputErr');
            } else {

                $('#sendWishListToMails').removeClass('inputErr');
            }
            if (response == '3') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_message') + "</p>";
                $('#sendWishListToMessage').addClass('inputErr');
            } else {

                $('#sendWishListToMessage').removeClass('inputErr');
            }
            if (response == '4') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_name') + "</p>";
                $('#sendWishListYourName').addClass('inputErr');
            } else {

                $('#sendWishListYourName').removeClass('inputErr');
            }
            if (response == '5' || response == '6') {

                $('#sendWishListYourMail').addClass('inputErr');

                if (response == '5') {
                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_my_mail') + "</p>";
                } else {
                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_my_mail_invalid') + "</p>";
                }
            } else {

                $('#sendWishListYourMail').removeClass('inputErr');
            }

            if (response == 'token_error') {
                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=error_token_security') + "</p>";
            }

            if (response.split(',')[0] == '7') {
                var s_adresse = response.substr(3);
                var sing = (response.split(',').length > 2) ? 'sendfriend_no_ok_plur' : 'sendfriend_no_ok_sing';
                content = '<div>' + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=' + sing) + '</div>';

            }
            if (response.split(',')[0] == '7' || response.split(',')[0] == 'token_error' || response.split(',')[0] == 'ok') {
                content_id = $("#sendfriend_alert_mail");

                $('#sendfriend_alert_mail').css({ display: 'block', opacity: 0 });
                $('#wrapper_sendwishlist').addClass('sended').css({
                    display: 'block',
                    opacity: 1
                }).animate({ opacity: 0 }, function () {
                    $('#wrapper_sendwishlist').css({ display: 'none' });
                    $('#sendfriend_alert_mail').animate({ opacity: 1 }, function () {
                        $('#sendfriend_alert_mail').css({ display: 'block' });
                    });
                });
            } else {
                $('#sendfriend_form_buttons').removeClass('loading');
            }

            content_id.html(content).css({ display: 'block' });

        });

    }


    return false;
}

/**
 * Add a shop to favourites
 * Used in : (boutique_details)
 */
function addToMyFavorites() {

    var is_favorite_elem = document.getElementById('is_favorite');

    var self = $(this);
    var magasin_id = self.attr('data-magasinid');
    var client_id = self.attr('data-clientid');
    var action = self.attr('data-action');

    // If we wish to destroy then we are in favorites_stores, delete the block
    if (action == 'destroy') {
        var act = 'del';
    } else {
        // Otherwise the page is store_details and we need to get the current state of the store to know whether to add or delete
        if (is_favorite_elem.value == '1') {
            var act = 'del';
        } else {
            var act = 'add';
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_manage_favorites_stores.php',
        type: 'post',
        dataType: 'html',
        data: 'act=' + act + '&magasin_id=' + magasin_id,
        success: function (response) {

            if (response) {

                // Destruction du bloc
                if (action == 'destroy') {
                    self.slideUp('slow', function () {
                        self.remove();
                    });
                } else {
                    if (act == 'add') {
                        var libelle = '<span>' + translate('delete_to_favorites') + '</span>';

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle = '<span>' + translate('add_to_favorites') + '</span>';

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        }
    });
}